import { useEffect, useState } from 'react'

function useSessionStorage(key) {
  const currentState =
    typeof window !== 'undefined' ? window.sessionStorage.getItem(key) : null

  // This somehow seems to cast the sessionStorage
  // value to boolean. Don't ask me how.
  const [value, setValue] = useState(currentState)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(key, value)
    }
  }, [key, value])

  return [value, setValue]
}

export default useSessionStorage

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { BufferAttribute, Fog } from 'three'
import { Canvas, useFrame, useThree, useUpdate } from 'react-three-fiber'
import { useSpring, animated } from 'react-spring/three'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import getTerrainMesh from '../../lib/terrainMesh'
import png from './suedbaden.png'
import colours from '../../styles/colours'
import styles from './datahills.module.scss'

function ImageGetter({ setter }) {
  // Get image data for the terrain mesh.
  const imageRef = useRef()
  const canvasRef = useRef()
  const [run, setRun] = useState(false)

  function getImageData() {
    // Only run if it hasn't run before.
    if (!run) {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      context.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height)
      setter(context.getImageData(0, 0, canvas.width, canvas.height).data)
    }
    setRun(true)
  }

  // Making certain the onload function kicks off (1)
  useEffect(() => {
    if (imageRef.current?.complete) getImageData('from useEffect')
  }, [imageRef])

  return (
    <>
      <img
        ref={imageRef}
        className={styles.hide}
        src={png}
        alt="img"
        onLoad={() => getImageData('from img')}
      />
      <canvas
        ref={canvasRef}
        className={styles.hide}
        width={256}
        height={256}
      />
    </>
  )
}
function TerrainMesh({ imageData }) {
  const mesh = useRef()

  // Update the buffer geometry.
  const ref = useUpdate(
    geo => {
      if (imageData) {
        const terrain = getTerrainMesh(imageData)
        geo.setAttribute('position', new BufferAttribute(terrain.vertices, 3))
        geo.setIndex(new BufferAttribute(terrain.indeces, 1))
        geo.computeVertexNormals()
        geo.computeBoundingSphere() // to know  when the frustum gets culled.
      }
    },
    [imageData]
  )

  // Move mesh on mousemove.
  useFrame(({ mouse }) => {
    if (mesh.current) {
      mesh.current.rotation.y +=
        0.05 * (mouse.x * 0.01 - mesh.current.rotation.y)
      mesh.current.rotation.x +=
        0.05 * (mouse.y * 0.01 - mesh.current.rotation.x)
      mesh.current.rotation.z +=
        0.05 * (mouse.y * 0.025 - mesh.current.rotation.z)
    }
  })

  // Animate the background.
  const { scene } = useThree()

  useEffect(() => {
    // Animate fog (needs fromTo).
    scene.fog = new Fog('white', 0, 0)
    const fog = { far: 0 }
    gsap.fromTo(
      fog,
      { far: 0 },
      { far: 250, duration: 2, onUpdate: () => (scene.fog.far = fog.far) }
    )

    // Animate on scroll.
    const pos = { y: 0 }
    const toFog = gsap.fromTo(fog, { far: 250 }, { far: 200 })
    const toPos = gsap.fromTo(pos, { y: 0 }, { y: -50 })
    const tl = gsap.timeline().add(toFog, 0).add(toPos, 0)

    // Scrolltrigger color.
    ScrollTrigger.create({
      animation: tl,
      trigger: '#landing',
      start: 'top top',
      scrub: true,
      onUpdate() {
        scene.fog.far = fog.far
        mesh.current.position.y = pos.y
      },
    })
    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill())
    }
  }, [])

  return (
    <animated.mesh ref={mesh} position={[-256 / 2, 0, -256 / 2]} frustumCulled>
      <bufferGeometry ref={ref} />
      <meshBasicMaterial wireframe color={colours.grey5} />
    </animated.mesh>
  )
}

function CameraMove({ setAnimationRan }) {
  const { camera } = useThree()

  // Switch off future animations (2)
  useEffect(() => {
    // prettier-ignore
    const timer = setTimeout(() => { setAnimationRan('ran') }, [250])
    return () => clearTimeout(timer)
  }, [])

  // Animate the camera.
  useSpring({
    from: { x: 1, y: 120, z: 1 },
    to: { x: 100, y: 100, z: 100 },
    delay: 4000,
    onFrame: ({ x, y, z }) => {
      if (camera && camera.position) {
        camera.position.set(x, y, z)
        camera.lookAt(0, 0, 0)
        camera.updateProjectionMatrix()
      }
    },
    config: {
      mass: 500,
      tension: 300,
      friction: 500,
    },
  })

  return null
}

function DataHills({ animationRan, setAnimationRan }) {
  const [imageData, setImageData] = useState()

  return (
    <div className={styles.hillContainer}>
      <ImageGetter setter={setImageData} />
      <Canvas
        webgl1
        camera={{
          fov: 25,
          near: 0.1,
          far: 1000,
          position: [100, 100, 100], // only affects camera after initial animation.
        }}
      >
        <TerrainMesh imageData={imageData} />
        {!(animationRan === 'ran') && (
          <CameraMove setAnimationRan={setAnimationRan} />
        )}
      </Canvas>
    </div>
  )
}

ImageGetter.propTypes = {
  setter: PropTypes.func,
}

TerrainMesh.propTypes = {
  imageData: PropTypes.object,
}

CameraMove.propTypes = {
  setAnimationRan: PropTypes.func,
}

DataHills.propTypes = {
  animationRan: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAnimationRan: PropTypes.func,
}

export default DataHills

// (1) See https://stackoverflow.com/a/59153135/3219033 or https://stackoverflow.com/a/39778416/3219033
// (2) Switches animation flag off in sessionStorage so it only runs once.
//     I ran this on spring end, but that triggered memory leak / useEffect cleanup error

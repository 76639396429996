import React, { useContext } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { Context as SectionContext } from '../context/sectionContext'
import sectionInfo from '../../../data/base/sectionInfo'
import styles from './footer.module.scss'

const Footer = () => {
  // Get section context to build header.
  const { selectedSection } = useContext(SectionContext)
  const { footer } = sectionInfo.filter(d => d.name === selectedSection)[0]

  return (
    // (1)
    <SwitchTransition mode="out-in">
      <CSSTransition
        classNames={{ ...styles }}
        key={footer}
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false)
        }}
      >
        <div className={styles.footer}>{footer}</div>
      </CSSTransition>
    </SwitchTransition>
  )
}
export default Footer

// (1) SwitchTransition makes sure old text moves out first before new ones
// come in: http://reactcommunity.org/react-transition-group/switch-transition
// CSS Transition updates on `key` change and applies classNames (that are spread
// in) at different enter and exit stages (https://reactcommunity.org/react-transition-group/css-transition).
// The `transitionend` transition controils the SwitchTransition mechanics, I believe.

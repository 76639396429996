/* eslint-disable react/display-name */
import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
  query PageQuery {
    site {
      siteMetadata {
        title
        author
        description
        keywords
        url
        image
        twitterUsername
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={data => {
      const metaData = data.site.siteMetadata
      return (
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="author" content={metaData.author} />
          <meta name="description" content={metaData.description} />
          <meta name="image" content={metaData.image} />
          <meta name="keywords" content={metaData.keywords.join(',')} />
          <meta property="og:url" content={metaData.url} />
          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:image" content={metaData.image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={metaData.twitterUsername} />
          <meta name="twitter:title" content={metaData.title} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image} />
        </Helmet>
      )
    }}
  />
)

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import GridItem from '../parts/gridItem'
import breaks from '../../styles/breakpoints'

// (1)
const GridContainer = styled.div`
  min-height: 100vh;
  padding: 6rem 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-auto-rows: 275px;
  justify-content: center;
  align-content: center;
  column-gap: 3rem;
  row-gap: 2rem;

  @media ${breaks.mobileUp} {
    grid-template-columns: repeat(auto-fill, 450px);
    grid-auto-rows: 350px;
  }
`

const Grid = ({ name, colour, url, items }) => (
  <GridContainer id={name} style={{ backgroundColor: colour }}>
    {items.map((d, i) => (
      <GridItem key={i} item={d} url={url} colour={colour} />
    ))}
  </GridContainer>
)

Grid.propTypes = {
  name: PropTypes.string,
  colour: PropTypes.string,
  url: PropTypes.string,
  items: PropTypes.array,
}

export default Grid

// (1) It's pretty hopeless to try to support this in ie11.
// This: https://stackoverflow.com/a/45787093/3219033 should
// help but doesn't/

import React from 'react'
import PropTypes from 'prop-types'
import styles from '@emotion/styled'
import { animated, useSpring } from 'react-spring'
import DataHills from '../datahills/datahills'
import useSessionStorage from '../hooks/useSessionStorage'
import colours from '../../styles/colours'

const Container = styles.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colours.black0b};
  pointer-events: none;
`

const AnimatedP = styles(animated.p)`
  margin: 0.25rem;
  font-size: 1.5em;
`

const AnimatedPS = styles(animated.p)`
  margin: 0.25rem;
  font-size: 0.9em;
`

const introLines = [
  {
    top: `I like to break your data grids`,
    middle: `to make coherent visual things`,
    bottom: `ooh.. pretty △ data mountains`,
  },
  {
    top: `I make visual things with data`,
    middle: `to show their pretty faces`,
    bottom: `(◔ ‿ ◔)`,
  },
  {
    top: `When you ask data nicely`,
    middle: `it might throw some pretty shapes`,
    bottom: `which I'm happy to help with...`,
  },
]

function getLine(lines) {
  const index = Math.floor(Math.random() * lines.length)
  return lines[index]
}

function Landing({ name, colour }) {
  // Init the session storage for the animation
  const [animationRan, setAnimationRan] = useSessionStorage('animationRan')

  const { o1 } = useSpring({
    o1: 1,
    from: { o1: animationRan === 'ran' ? 1 : 0 },
    delay: 1000,
    config: { duration: 2000 },
  })
  const { o2 } = useSpring({
    o2: 1,
    from: { o2: animationRan === 'ran' ? 1 : 0 },
    delay: 7000,
    config: { duration: 1000 },
  })
  const { o3 } = useSpring({
    o3: 1,
    from: { o3: animationRan === 'ran' ? 1 : 0 },
    delay: 9000,
    config: { duration: 1000 },
  })

  const text = getLine(introLines)

  return (
    <div
      id="landing"
      style={{ width: '100vw', height: '100vh', background: colour }}
    >
      <DataHills
        animationRan={animationRan}
        setAnimationRan={setAnimationRan}
      />
      <Container id={name}>
        <AnimatedP style={{ opacity: o1 }}>{text.top}</AnimatedP>
        <AnimatedP style={{ opacity: o2 }}>{text.middle}</AnimatedP>
        <AnimatedPS style={{ opacity: o3 }}>{text.bottom}</AnimatedPS>
      </Container>
    </div>
  )
}

Landing.propTypes = {
  name: PropTypes.string,
  colour: PropTypes.string,
}

export default Landing

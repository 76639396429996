/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-cond-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable one-var */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-destructuring */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */

function _instanceof(left, right) {
  if (
    right != null &&
    typeof Symbol !== 'undefined' &&
    right[Symbol.hasInstance]
  ) {
    return !!right[Symbol.hasInstance](left)
  } else {
    return left instanceof right
  }
}

function _classCallCheck(instance, Constructor) {
  if (!_instanceof(instance, Constructor)) {
    throw new TypeError('Cannot call a class as a function')
  }
}

function _defineProperties(target, props) {
  for (var i = 0; i < props.length; i++) {
    var descriptor = props[i]
    descriptor.enumerable = descriptor.enumerable || false
    descriptor.configurable = true
    if ('value' in descriptor) descriptor.writable = true
    Object.defineProperty(target, descriptor.key, descriptor)
  }
}

function _createClass(Constructor, protoProps, staticProps) {
  if (protoProps) _defineProperties(Constructor.prototype, protoProps)
  if (staticProps) _defineProperties(Constructor, staticProps)
  return Constructor
}

var Tile = /* #__PURE__ */ (function () {
  function Tile(terrain, martini) {
    _classCallCheck(this, Tile)

    var size = martini.gridSize
    if (terrain.length !== size * size)
      throw new Error(
        'Expected terrain data of length '
          .concat(size * size, ' (')
          .concat(size, ' x ')
          .concat(size, '), got ')
          .concat(terrain.length, '.')
      )
    this.terrain = terrain
    this.martini = martini
    this.errors = new Float32Array(terrain.length)
    this.update()
  }

  _createClass(Tile, [
    {
      key: 'update',
      value: function update() {
        // eslint-disable-next-line one-var
        var _this$martini = this.martini,
          numTriangles = _this$martini.numTriangles,
          numParentTriangles = _this$martini.numParentTriangles,
          coords = _this$martini.coords,
          size = _this$martini.gridSize
        var terrain = this.terrain,
          errors = this.errors // iterate over all possible triangles, starting from the smallest level

        for (var i = numTriangles - 1; i >= 0; i--) {
          var k = i * 4
          var ax = coords[k + 0]
          var ay = coords[k + 1]
          var bx = coords[k + 2]
          var by = coords[k + 3]
          var mx = (ax + bx) >> 1
          var my = (ay + by) >> 1
          var cx = mx + my - ay
          var cy = my + ax - mx // calculate error in the middle of the long edge of the triangle

          var interpolatedHeight =
            (terrain[ay * size + ax] + terrain[by * size + bx]) / 2
          var middleIndex = my * size + mx
          var middleError = Math.abs(interpolatedHeight - terrain[middleIndex])
          errors[middleIndex] = Math.max(errors[middleIndex], middleError)

          if (i < numParentTriangles) {
            // bigger triangles; accumulate error with children
            var leftChildIndex = ((ay + cy) >> 1) * size + ((ax + cx) >> 1)
            var rightChildIndex = ((by + cy) >> 1) * size + ((bx + cx) >> 1)
            errors[middleIndex] = Math.max(
              errors[middleIndex],
              errors[leftChildIndex],
              errors[rightChildIndex]
            )
          }
        }
      },
    },
    {
      key: 'getMesh',
      value: function getMesh() {
        var maxError =
          arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0
        var _this$martini2 = this.martini,
          size = _this$martini2.gridSize,
          indices = _this$martini2.indices
        var errors = this.errors
        var numVertices = 0
        var numTriangles = 0
        var max = size - 1 // use an index grid to keep track of vertices that were already used to avoid duplication

        indices.fill(0) // retrieve mesh in two stages that both traverse the error map:
        // - countElements: find used vertices (and assign each an index), and count triangles (for minimum allocation)
        // - processTriangle: fill the allocated vertices & triangles typed arrays

        function countElements(ax, ay, bx, by, cx, cy) {
          var mx = (ax + bx) >> 1
          var my = (ay + by) >> 1

          if (
            Math.abs(ax - cx) + Math.abs(ay - cy) > 1 &&
            errors[my * size + mx] > maxError
          ) {
            countElements(cx, cy, ax, ay, mx, my)
            countElements(bx, by, cx, cy, mx, my)
          } else {
            indices[ay * size + ax] = indices[ay * size + ax] || ++numVertices
            indices[by * size + bx] = indices[by * size + bx] || ++numVertices
            indices[cy * size + cx] = indices[cy * size + cx] || ++numVertices
            numTriangles++
          }
        }

        countElements(0, 0, max, max, max, 0)
        countElements(max, max, 0, 0, 0, max)
        var vertices = new Uint16Array(numVertices * 2)
        var triangles = new Uint32Array(numTriangles * 3)
        var triIndex = 0

        function processTriangle(ax, ay, bx, by, cx, cy) {
          var mx = (ax + bx) >> 1
          var my = (ay + by) >> 1

          if (
            Math.abs(ax - cx) + Math.abs(ay - cy) > 1 &&
            errors[my * size + mx] > maxError
          ) {
            // triangle doesn't approximate the surface well enough; drill down further
            processTriangle(cx, cy, ax, ay, mx, my)
            processTriangle(bx, by, cx, cy, mx, my)
          } else {
            // add a triangle
            var a = indices[ay * size + ax] - 1
            var b = indices[by * size + bx] - 1
            var c = indices[cy * size + cx] - 1
            vertices[2 * a] = ax
            vertices[2 * a + 1] = ay
            vertices[2 * b] = bx
            vertices[2 * b + 1] = by
            vertices[2 * c] = cx
            vertices[2 * c + 1] = cy
            triangles[triIndex++] = a
            triangles[triIndex++] = b
            triangles[triIndex++] = c
          }
        }

        processTriangle(0, 0, max, max, max, 0)
        processTriangle(max, max, 0, 0, 0, max)
        return {
          vertices,
          triangles,
        }
      },
    },
  ])

  return Tile
})()

var Martini = /* #__PURE__ */ (function () {
  function Martini() {
    var gridSize =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 257

    _classCallCheck(this, Martini)

    this.gridSize = gridSize
    var tileSize = gridSize - 1
    if (tileSize & (tileSize - 1))
      throw new Error(
        'Expected grid size to be 2^n+1, got '.concat(gridSize, '.')
      )
    this.numTriangles = tileSize * tileSize * 2 - 2
    this.numParentTriangles = this.numTriangles - tileSize * tileSize
    this.indices = new Uint32Array(this.gridSize * this.gridSize) // coordinates for all possible triangles in an RTIN tile

    this.coords = new Uint16Array(this.numTriangles * 4) // get triangle coordinates from its index in an implicit binary tree

    for (var i = 0; i < this.numTriangles; i++) {
      var id = i + 2
      var ax = 0,
        ay = 0,
        bx = 0,
        by = 0,
        cx = 0,
        cy = 0

      if (id & 1) {
        bx = by = cx = tileSize // bottom-left triangle
      } else {
        ax = ay = cy = tileSize // top-right triangle
      }

      while ((id >>= 1) > 1) {
        var mx = (ax + bx) >> 1
        var my = (ay + by) >> 1

        if (id & 1) {
          // left half
          bx = ax
          by = ay
          ax = cx
          ay = cy
        } else {
          // right half
          ax = bx
          ay = by
          bx = cx
          by = cy
        }

        cx = mx
        cy = my
      }

      var k = i * 4
      this.coords[k + 0] = ax
      this.coords[k + 1] = ay
      this.coords[k + 2] = bx
      this.coords[k + 3] = by
    }
  }

  _createClass(Martini, [
    {
      key: 'createTile',
      value: function createTile(terrain) {
        return new Tile(terrain, this)
      },
    },
  ])

  return Martini
})()

export default Martini

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { setColour } from './utils'
import styles from './gridItem.module.scss'

// Gifs see 1)
import winterOlympics from '../../../data/projects/winter-olympics/winter-olympics.gif'
import zoom from '../../../data/posts/zoom/zoom.gif'

const gifs = {
  winterOlympics,
  zoom,
}

const GridImage = styled.div`
  height: 70%;
  box-shadow: 10px 10px 40px ${props => setColour(props.colour, 0, 0.2)};

  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 5px 5px 10px ${props => setColour(props.colour, 0, 0.2)};
  }
  &:active {
    box-shadow: 2px 2px 5px ${props => setColour(props.colour, 0, 0.2)};
  }
`

const GridItem = ({ item, url, colour }) => (
  <div className={styles.gridItem}>
    <Link to={`/${url}/${item.slug}`}>
      <div className={styles.gridHeader}>
        <h3>{item.name}</h3>
        <h5>{`${item.desc} | ${item.year}`}</h5>
      </div>
      <GridImage colour={colour}>
        {item.gif ? (
          <img className={styles.gif} src={gifs[item.gif]} alt={item.gif} />
        ) : (
          <Img
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
            fluid={item.image.childImageSharp.fluid}
          />
        )}
      </GridImage>
    </Link>
  </div>
)

GridItem.propTypes = {
  item: PropTypes.object,
  url: PropTypes.string,
  colour: PropTypes.string,
}

export default GridItem

// (1) Gatsby Image can't work with gifs. So I can't resolve them through the
// same graphql data layer. Instead, I have a normal fallback jpg in each
// data json object (need a jpg in there). But I also have a "gif" property
// only for projects with a gif. I also have the gif saved in the image folder
// and imported in this file. The import gets a name given by the json "gif"
// property. In the project component bit I check if the project has a "gif"
// property. If yes, it loads the gif into a normal image tag. If not, it
// builds out the normal image.

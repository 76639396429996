/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import { graphql } from 'gatsby'
import { Provider as SectionProvider } from '../components/context/sectionContext'
import sectionInfo from '../../data/base/sectionInfo'
import Head from '../components/sections/head'
import Landing from '../components/sections/landing'
import Grid from '../components/sections/grid'
import Layout from '../components/parts/layout'
import About from '../components/sections/about'
import Header from '../components/parts/header'
import Footer from '../components/parts/footer'

// Init markdown code syntax highlighting.
deckDeckGoHighlightElement()

// Query for projects and posts.
export const query = graphql`
  query {
    projects: allProjectsJson {
      nodes {
        name
        desc
        link
        year
        slug
        gif
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    posts: allPostsJson {
      nodes {
        name
        desc
        year
        slug
        gif
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    me: allFile(filter: { relativeDirectory: { eq: "about/me" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    clients: allFile(filter: { relativeDirectory: { eq: "about/clients" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    icons: allFile(filter: { relativeDirectory: { eq: "about/icons" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default function Index({ data }) {
  return (
    <>
      <Head />
      <Layout>
        <SectionProvider sections={sectionInfo} selectedSection="start">
          <Header home />
          <Landing name={sectionInfo[0].name} colour={sectionInfo[0].colour} />
          <Grid
            name={sectionInfo[1].name}
            colour={sectionInfo[1].colour}
            url={sectionInfo[1].url}
            items={data.projects.nodes}
          />
          <Grid
            name={sectionInfo[2].name}
            colour={sectionInfo[2].colour}
            url={sectionInfo[2].url}
            items={data.posts.nodes}
          />
          <About
            name={sectionInfo[3].name}
            colour={sectionInfo[3].colour}
            imageMe={data.me.nodes}
            imageClients={data.clients.nodes}
            imageIcons={data.icons.nodes}
          />
          <Footer />
        </SectionProvider>
      </Layout>
    </>
  )
}

// (1) For some reason the sectionInfo colours don't caryy through
//     to the background styles in the components (in the production
//     buld only - works in dev), so we need to hard copy them here.

/* eslint-disable no-plusplus */
import Martini from './martini' // npm Martini is ES6. This is ported to ES5 IE11 conform.

function getTerrainData(imageData, tileSize, gridSize) {
  const terrain = new Float32Array(gridSize * gridSize)

  // decode terrain values
  for (let y = 0; y < tileSize; y++) {
    for (let x = 0; x < tileSize; x++) {
      const k = (y * tileSize + x) * 4
      const r = 1 // (1)
      const g = imageData[k + 1]
      const b = imageData[k + 2]

      terrain[y * gridSize + x] =
        (r * 256 * 256 + g * 256.0 + b) / 10.0 - 10000.0
    }
  }
  // backfill right and bottom borders
  for (let x = 0; x < gridSize - 1; x++) {
    terrain[gridSize * (gridSize - 1) + x] =
      terrain[gridSize * (gridSize - 2) + x]
  }
  for (let y = 0; y < gridSize; y++) {
    terrain[gridSize * y + gridSize - 1] = terrain[gridSize * y + gridSize - 2]
  }

  return terrain
}

function getTerrainMesh(imageData) {
  // Set variables.
  const tileSize = 256
  const gridSize = tileSize + 1
  const error = 1

  // Get the terrain data.
  const terrainData = getTerrainData(imageData, tileSize, gridSize)

  // Get the martini data.
  const martini = new Martini()
  const tile = martini.createTile(terrainData)
  const terrainMesh = tile.getMesh(error)

  // The mesh's vertices are not in the form THREE is expecting vertices yet...
  // See: https://github.com/mapbox/martini/issues/5#issuecomment-527467649
  let vertices = []
  for (let i = 0; i < terrainMesh.vertices.length / 2; i++) {
    const x = terrainMesh.vertices[i * 2]
    const y = terrainMesh.vertices[i * 2 + 1]
    vertices.push(x)
    vertices.push(terrainData[y * 257 + x] / 60) // Play with this divisor to adjust height.
    vertices.push(y)
  }
  vertices = new Float32Array(vertices)

  const result = {
    terrain: terrainData,
    vertices,
    indeces: terrainMesh.triangles,
  }

  return result
}

export default getTerrainMesh

// (1) Firefox gets an r value of 60 or 61. I don't know why,
// (possibly as browsers process and read pixels differently).
// All r values for our model need to be 1 (terrain rgb seems
// fully defined by g and b values), so we just set r to 1.
